
export default {
  updateUserCommit (state, data) {
    state.user = data
  },

  doCheeringCommit (state, data) {
    state.user.coin = data
  },

  doClearCommit (state) {
    state.user = {
      name: '',
      user_id: 0,
      coin: 0
    }
    state.staking = []
  },

  changeNameCommit (state, data) {
    state.user.name = data
  },
  changeReferralCommit (state, data) {
    state.referral.name = data
  },

  setStakingCommit (state, data) {
    state.staking = data
  }
}
