export default {
  setId (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('setIdCommit', payload, { root: true })
      resolve()
    })
  },

  setAddress (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('setAddressCommit', payload)
      resolve()
    })
  },

  setAmount (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('setAmountCommit', payload)
      resolve()
    })
  },

  setProvider (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('setProviderCommit', payload)
      resolve()
    })
  }
}
