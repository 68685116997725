import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import SideBarItems from '../../FackApi/json/GlobalSearch'

const state = {
  level: [{
    level: 1,
    kdia_charge: 0,
    posting_max: 10,
    mining_value: 1
  },
  {
    level: 2,
    kdia_charge: 1282050,
    posting_max: 5,
    mining_value: 5
  },
  {
    level: 3,
    kdia_charge: 2564100,
    posting_max: 10,
    mining_value: 10
  },
  {
    level: 4,
    kdia_charge: 5128200,
    posting_max: 15,
    mining_value: 20
  },
  {
    level: 5,
    kdia_charge: 7689300,
    posting_max: 20,
    mining_value: 30
  },
  {
    level: 6,
    kdia_charge: 12820500,
    posting_max: 30,
    mining_value: 50
  },
  {
    level: 7,
    kdia_charge: 17950000,
    posting_max: 40,
    mining_value: 70
  },
  {
    level: 8,
    kdia_charge: 33333333,
    posting_max: 50,
    mining_value: 130
  },
  {
    level: 9,
    kdia_charge: 0,
    posting_max: 0,
    mining_value: 0
  },
  {
    level: 10,
    kdia_charge: 0,
    posting_max: 30,
    mining_value: 1
  }],
  youtuber: [],
  horizontalMenu: false,
  miniSidebarMenu: false,
  lang: { title: 'English', value: 'en', image: require('../../assets/images/small/flag-01.png') },
  langOption: [
    { title: 'English', value: 'en', image: require('../../assets/images/small/flag-01.png') },
    { title: 'Arabic', value: 'ar', image: require('../../assets/images/small/flag-500.png') },
    { title: 'Chinese', value: 'chi', image: require('../../assets/images/small/flag-300.png') },
    { title: 'Hindi', value: 'hi', image: require('../../assets/images/small/flag-100.png') },
    { title: 'Greek', value: 'gr', image: require('../../assets/images/small/flag-400.png') },
    { title: 'Franch', value: 'fr', image: require('../../assets/images/small/flag-200.png') }
  ],
  colors: [
    { primary: '#827af3', primaryLight: '#b47af3', bodyBgLight: '#efeefd', bodyBgDark: '#1d203f' },
    { primary: '#e07af3', primaryLight: '#f37ab7', bodyBgLight: '#f7eefd', bodyBgDark: '#1d203f' },
    { primary: '#6475c7', primaryLight: '#7abbf3', bodyBgLight: '#eaf5ff', bodyBgDark: '#1d203f' },
    { primary: '#c76464', primaryLight: '#f3c37a', bodyBgLight: '#fff8ea', bodyBgDark: '#1d203f' },
    { primary: '#c764ad', primaryLight: '#de8ba9', bodyBgLight: '#ffeaf5', bodyBgDark: '#1d203f' },
    { primary: '#64c7ac', primaryLight: '#a3f37a', bodyBgLight: '#f0ffea', bodyBgDark: '#1d203f' },
    { primary: '#8ac764', primaryLight: '#dbf37a', bodyBgLight: '#f7ffea', bodyBgDark: '#1d203f' }
  ],
  authUser: {
    auth: false,
    authType: false,
    user: {}
  },
  users: [
    {
      id: 'sh46s546sdg564sdffs4hsd6fh',
      name: 'Admin Demo',
      mobileNo: null,
      email: 'admin@demo.com',
      profileImage: null,
      password: 'admin123'
    }
  ],
  globalSearch: SideBarItems,
  bookmark: [
    {
      title: '홈',
      link: { name: 'home.main' },
      is_icon_class: true,
      icon: 'ri-home-line'
    },
    {
      title: '게시판',
      link: { name: 'dashboard.latest' },
      is_icon_class: true,
      icon: 'ri-file-list-line'
    }
  ],
  // activePage: {
  //   name: 'Dashboard',
  //   breadCrumb: [
  //     {
  //       html: '<i class="ri-home-4-line mr-1 float-left"></i>Home',
  //       to: { name: 'dashboard.list' }
  //     },
  //     {
  //       text: '',
  //       href: '#'
  //     }
  //   ]
  // },
  layoutMode: {
    dark: false,
    rtl: false
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
