<template>
  <router-view/>
</template>
<script>
import { core } from './config/pluginInit'
import category from './services/category'
import { mapActions } from 'vuex'
import setting from './services/setting'

export default {
  name: 'App',
  components: {
  },
  mounted () {
    const recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute(
      'src',
      'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    )
    document.head.appendChild(recaptchaScript)

    this.getLevel()
    this.getList()
    this.getConsum()
    core.mainIndex()
    this.googleTranslateElementInit()
  },
  methods: {
    ...mapActions({
      setCategory: 'Category/update',
      setConsum: 'Category/consum',
      setLevel: 'Setting/getLevelInfo'
    }),
    getLevel () {
      setting.getLevel().then(res =>
        this.setLevel(res.data.level)
      )
    },
    getList () {
      category.getList().then(res => {
        const list = res.data.category_list

        for (let i = 0; i < list.length; i++) {
          list[i].open = false
        }
        this.setCategory(list)
      })
    },

    getConsum () {
      category.getConsum().then(res => {
        const list = res.data
        this.setConsum(list)
      })
    },
    googleTranslateElementInit () {
      // eslint-disable-next-line no-new, no-undef
      new google.translate.TranslateElement({
        // pageLanguage: 'ko',
        // eslint-disable-next-line no-undef
        layout: google.translate.TranslateElement.InlineLayout.HORIZONTAL,
        autoDisplay: true
      }, 'google_translate_element')
    }
  }
}
</script>
<style lang="scss">
  @import "assets/scss/style.scss";
</style>
<style type="text/css">
.fixed-con {
  z-index: 9724790009779558 !important;
  background-color: #f7f8fc;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
}

/* .goog-te-banner-frame.skiptranslate {
    display: none !important;
    }
body {
    top: 0px !important;
    }
    body > .skiptranslate {
    display: none;
}
  .VIpgJd-ZVi9od-ORHb { display: none;} */

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf {
  z-index: 9724790009779 !important;
  top: 0;
  left: unset;
  right: -5px;
  display: none !important;
  border-radius: 50%;
  border: 2px solid gold;
}

.VIpgJd-ZVi9od-aZ2wEe-OiiCO {
  width: 80px;
  height: 80px;
}

/*hide google translate link | logo | banner-frame */
.goog-logo-link,
.gskiptranslate,
.goog-te-gadget span,
.goog-te-banner-frame,
#goog-gt-tt,
.goog-te-balloon-frame,
div#goog-gt- {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
  font-size: 0px;
  max-width: 200px;
}
.goog-te-combo {
  width: 100%;
}

.goog-text-highlight {
  background: none !important;
  box-shadow: none !important;
}

/*google translate Dropdown */

#google_translate_element select {
  background: #f6edfd;
  color: #383ffa;
  border: none;
  border-radius: 3px;
  padding: 6px 8px
}</style>
