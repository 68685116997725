import Vue from 'vue'
import VueRouter from 'vue-router'
/* Layouts */
const CommonLayout = () => import('../layouts/CommonLayout.vue')
const DashboardLayout1 = () => import('../layouts/DashboardLayout1.vue')

// 홈
const Home = () => import('../page/Home/Home')
// 게시물
const DashboardPost = () => import('../page/Dashboard/DashboardPost')
const DashboardDetail = () => import('../page/Dashboard/DashboardDetail')
const DashboardEdit = () => import('../page/Dashboard/DashboardEdit.vue')
const DashboardPosted = () => import('../page/Dashboard/DashboardPosted')
const DashboardComment = () => import('../page/Dashboard/DashboardComment')
const DashboardLatest = () => import('../page/Dashboard/DashboardLatest')

const DashboardList = () => import('../page/Dashboard/DashboardList.vue')
// const DashboardNotice = () => import('../page/Dashboard/DashboardNotice')
// const DashboardForFree = () => import('../page/Dashboard/DashboardForFree')
// const DashboardNews = () => import('../page/Dashboard/DashboardNews')

/* Auth */
const SignIn = () => import('../page/Auth/SignIn.vue')
const SignUp = () => import('../page/Auth/SignUp')
const RecoverPassword = () => import('../page/Auth/RecoverPassword')
const LockScreen1 = () => import('../views1/AuthPages/Default/LockScreen1')
const ConfirmMail1 = () => import('../views1/AuthPages/Default/ConfirmMail1')

/* User */
const Profile = () => import('../page/User/Profile.vue')
const StakingList = () => import('../page/User/StakingList.vue')
const DWList = () => import('../page/User/DWList.vue')
const UseageList = () => import('../page/User/UseageList.vue')
const KdiaDList = () => import('../page/User/KdiaDList.vue')
// const ProfileEdit = () => import('../page/User/ProfileEdit')

Vue.use(VueRouter)

const authChildRoutes = (prop) => [
  {
    path: 'sign-in',
    name: prop + '.sign-in',
    component: SignIn
  },
  {
    path: 'sign-up',
    name: prop + '.sign-up',
    component: SignUp
  },
  {
    path: 'password-reset',
    name: prop + '.password-reset',
    component: RecoverPassword
  },
  {
    path: 'lock-screen1',
    name: prop + '.lock-screen1',
    component: LockScreen1
  },
  {
    path: 'confirm-mail1',
    name: prop + '.confirm-mail1',
    component: ConfirmMail1
  }
]

const DashboardChildRoutes = (prop) => [
  {
    // 최신 글
    path: 'latest',
    name: prop + '.latest',
    component: DashboardLatest
  },
  {
    // 게시글 보기
    path: ':item/:category/list/:pk',
    name: prop + '.detail',
    component: DashboardDetail,
    props: true
  },
  {
    // 게시글 수정
    path: 'edit/:item/:category/:pk',
    name: prop + '.edit',
    component: DashboardEdit,
    props: true
  },
  {
    // 글쓰기
    path: 'post',
    name: prop + '.post',
    component: DashboardPost
  },
  {
    // 내가 작성한 댓글
    path: 'my-comment',
    name: prop + '.my-comment',
    component: DashboardComment
  },
  {
    // 내가 작성한 게시글
    path: 'my-posted',
    name: prop + '.my-posted',
    component: DashboardPosted
  },
  {
    // 카테고리
    path: ':item/:category/list',
    name: prop + '.categoryList',
    component: DashboardList
  }

  // {
  //   // 공지사항
  //   path: 'notice',
  //   name: prop + '.notice',
  //   component: DashboardNotice
  // },
  // {
  //   // 자유 게시판
  //   path: 'for-free',
  //   name: prop + '.for-free',
  //   component: DashboardForFree
  // },
  // {
  //   // 코인 소개
  //   path: 'news',
  //   name: prop + '.news',
  //   component: DashboardNews
  // }
]

const userChildRoute = (prop) => [
  {
    path: 'profile',
    name: prop + '.profile',
    meta: { auth: true, name: 'Profile' },
    component: Profile
  },
  {
    path: 'staking-list',
    name: prop + '.stakingList',
    meta: { auth: true, name: 'stakingList' },
    component: StakingList
  },
  {
    path: 'deposit-withdraw-list',
    name: prop + '.dwList',
    meta: { auth: true, name: 'dwList' },
    component: DWList
  },
  {
    path: 'useage-list',
    name: prop + '.useageList',
    meta: { auth: true, name: 'useageList' },
    component: UseageList
  },
  {
    path: 'kdia-deposit-list',
    name: prop + '.kdiaDList',
    meta: { auth: true, name: 'kdiaDList' },
    component: KdiaDList
  }
]

const routes = [
  {
    path: '/',
    name: '',
    // component: DashboardLayout,
    component: CommonLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'home.main',
        component: Home
      }
    ]
  },
  {
    path: '/auth',
    name: 'auth',
    component: CommonLayout,
    // component: CommonLayout,
    meta: { auth: true },
    children: authChildRoutes('auth')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardLayout1,
    meta: { auth: true },
    children: DashboardChildRoutes('dashboard')
  },
  {
    path: '/user',
    name: 'user',
    component: CommonLayout,
    meta: { auth: true },
    children: userChildRoute('user')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/auth/sign-in', '/auto/sign-up']
  if (publicPages.includes(to.path)) {
    const token = localStorage.getItem('hypercert_access_token')
    if (token !== null) {
      router.replace({ name: 'home.main' })
    }
  }
  next()
})

export default router
