
export default {
  setIdCommit (state, data) {
    state.wallet.walletId = data
  },
  setAddressCommit (state, data) {
    state.wallet.address = data
  },

  setAmountCommit (state, data) {
    state.wallet.amount = data
  },

  setProviderCommit (state, data) {
    state.provider = data
  }
}
