import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  stakingUser: [],
  stakingWallet: [{ wallet_address: '', start_amount: '', expire_date: '' }]
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
