import '@babel/polyfill'
import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueClipboard from 'vue-clipboard2'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'mutationobserver-shim'
import './Utils/fliter'
import App from './App.vue'
import router from './router'
import store from './store'
import Raphael from 'raphael/raphael'
import './plugins'
import AlgoliaComponents from 'vue-instantsearch'
import i18n from './i18n'
import './directives'
import './assets/scss/main.scss'
import VueMobileDetection from 'vue-mobile-detection'
global.Raphael = Raphael
global.jQuery = require('jquery')
Vue.use(AlgoliaComponents)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueClipboard)

Vue.config.productionTip = false

Vue.use(VueMobileDetection)

var $ = global.jQuery
window.$ = $

const vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

window.vm = vm
