
export default {
  updateUserStakingCommit (state, data) {
    state.stakingUser = data
  },

  updateWalletStakingCommit (state, data) {
    state.stakingWallet = data
  }
}
