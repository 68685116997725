import client from './index'

export default {
  /**
   * 카테고리 전체 리스트
   */
  async getList () {
    try {
      return client.json.get('/category/list')
    } catch (err) {
      // console.log(err)
    }
  },
  /**
   * 소 카테고리 리스트
   * @param {string} category 대카테고리 리스트
   */
  async getChild (category) {
    try {
      return client.json.get(`/category/${category}`)
    } catch (err) {
      // console.log(err)
    }
  },
  /**
   * 꽃달기, 글작성 리워드 값 받아오기
   */
  async getConsum () {
    try {
      return client.json.get('/admin/price')
    } catch (err) {
    // console.log(err)
    }
  }

}
