
export default {
  getWalletListCommit (state, data) {
    state.walletList = data
  },

  pushWalletCommit (state, data) {
    state.walletList.push(data)
  },

  setKlipQRCommit (state, data) {
    state.klipQR = data
  }
}
