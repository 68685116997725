import axios from 'axios'
import constant from '../config/constant'

const userImage = `${constant.webBaseURL}/image/`

const json = axios.create({
  baseURL: constant.webBaseURL,
  headers: {
    'Content-Type': 'application/json'
  }
})

json.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('hypercert_access_token') ?? ''
    config.headers.token = token
    return config
  }
)

// 응답 인터셉터 추가
json.interceptors.response.use(
  function (response) {
    if (response.data.message === 'Token validate') {
      localStorage.removeItem('hypercert_access_token')
      alert('다시 로그인을 해주세요.')
      window.location.href = `${window.location.protocol}//${window.location.host}/auth/sign-in`
      return
    }
    return response
  },
  function (error) {
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          break
        default:
          return Promise.reject(error)
      }
    }
    return Promise.reject(error)
  }
)

const formData = axios.create({
  baseURL: constant.webBaseURL,
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})

formData.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('hypercert_access_token') ?? ''
    config.headers.token = token
    return config
  }
)

// 응답 인터셉터 추가
formData.interceptors.response.use(
  function (response) {
    if (response.data.message === 'Token validate') {
      localStorage.removeItem('hypercert_access_token')
      alert('다시 로그인을 해주세요.')
      window.location.href = `${window.location.protocol}//${window.location.host}/auth/sign-in`
      return
    }
    return response
  },
  function (error) {
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          break
        default:
          return Promise.reject(error)
      }
    }
    return Promise.reject(error)
  }
)

const client = {
  json, formData, userImage
}

export default client
