export default {
  update (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('updateCommit', payload)
      resolve()
    })
  },
  open (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('openCommit', payload)
      resolve()
    })
  },
  consum (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('consumCommit', payload)
      resolve()
    })
  }
}
