import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  user: {
    role: '',
    name: '',
    user_id: 0,
    coin: 0,
    referral: null
  },
  staking: []
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
