import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  wallet: {
    walletId: '',
    address: '',
    amount: ''
  },
  provider: null
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
