export default {
  getWalletList (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('getWalletListCommit', payload)
      resolve()
    })
  },

  pushWallet (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('pushWalletCommit', payload)
      resolve()
    })
  },

  setKlipQR (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('setKlipQRCommit', payload)
      resolve()
    })
  }
}
