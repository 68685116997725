export default {
  updateUserInfo (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('updateUserCommit', payload)
      resolve()
    })
  },

  doCheering (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('doCheeringCommit', payload)
      resolve()
    })
  },

  doClear (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('doClearCommit', payload)
      resolve()
    })
  },

  changeName (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('changeNameCommit', payload)
      resolve()
    })
  },

  changeReferral (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('changeReferralCommit', payload)
      resolve()
    })
  },

  setStaking (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('setStakingCommit', payload)
      resolve()
    })
  }
}
