import client from './index'

export default {
/**
   * 금일 방문자
   */
  async visit () {
    try {
      return client.json.get('/visit')
    } catch (err) {
      // console.log('<GET> auth.visit')
      // console.log(err)
    }
  },

  /**
   * 레벨 권한 리스트
   */
  async getLevel () {
    try {
      return client.json.get('/admin/level')
    } catch (err) {
      // // console.log('<POST> auth.setInfo')
      // // console.log(err)
    }
  },

  /**
   * 채굴 랭킹
   */
  async getRank () {
    try {
      return client.json.get('/rank/mining')
    } catch (err) {
      // // console.log('<POST> auth.setInfo')
      // // console.log(err)
    }
  }
}
