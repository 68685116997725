
export default {
  updateCommit (state, data) {
    state.category = data
  },
  openCommit (state, data) {
    state.category[data].open = !state.category[data].open
  },
  consumCommit (state, data) {
    state.consum = data
  }
}
