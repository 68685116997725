export default {
  updateUserStaking (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('updateUserStakingCommit', payload)
      resolve()
    })
  },

  updateWalletStaking (context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('updateWalletStakingCommit', payload)
      resolve()
    })
  }
}
